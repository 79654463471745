import moment from 'moment'

export default {
    utcToLocalTime(time, format) {
        return moment(time).tz('Europe/Athens').format(format)
    },
    toIsoString(dateTime) {
        return moment(dateTime).toISOString()
    }
}
